.sec-home-top,
.sec-como-top,
.sec-somos-top,
.sec-contact-main {
	p {
		-webkit-animation-duration: 1.5s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1.5s;
		animation-fill-mode: both;
		-webkit-animation: 1s ease 0s normal forwards 1 fadein;
		animation: 1s ease 0s normal forwards 1 fadein;
		opacity: 1;
		line-height: 23px;
	}
}

.sec-home-top {
	p {
		margin-left: 0;
		line-height: 1.2;
	}
	#t1 b {
		margin: 2px 0;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		text-transform: uppercase;
		padding: 0 1.8rem;
	}
	#t1 .txtb {
		color: $white;
		font-size: 40px;
		font-weight: 100;
	}
	#t1 .txtw {
		color: $white;
		font-size: 16px;
		font-weight: 100;
	}
	.container-fluid {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}
}

.sec-home-top {
	@include bg-top-page("cb-header.jpeg");
}
.sec-cont-top {
	//@include bg-top-page("neurona.jpg");
	min-height: 5rem;
}
.sec-serv-top {
	//@include bg-top-page("salsa-serv.jpg");
	min-height: 5rem;
}
.sec-como-top {
	@include bg-top-page("wanted-bkg.jpg");
}
.sec-osomos-top {
	@include bg-top-page("ofera-somos.jpg");
}
.sec-contact-main {
	@include bg-top-page("ofera-contact.jpg");
	.container-fluid {
		background-position: center !important;
	}
}

.swiper-pagination-bullet-active {
	background: padding-box $brand-primary !important;
}

.custom-col {
	max-width: 1100px;
	flex: 1;
}

.sec-home .sec-slider.second{
	background: $black;
}

.sec-home .sec-slider.first{
	position: absolute;
    top: 2rem;
    width: 100%;
}

.swiper-wrapper {
    height: 720px;
}

#salsalab-slider-h .swiper-slide{
	background-repeat: no-repeat;
	background-size: contain;
	height: 540px;
	background-size: cover;
}
#salsalab-slider-y .swiper-slide{
	background-repeat: no-repeat;
	background-size: contain;
	height: 712px;
    width: 459px;
	background-size: cover;
	margin: auto;
}

#salsalab-slider-h {
	height: 620px; 
	.swiper-wrapper {
		height: 620px;
	}
}

#salsalab-slider-y {
	height: 780px;
	margin-top: 2rem;
}

.swiper-pagination-bullet {
	border: 1px solid $brand-secondary;
}

// ****************************
// conexiones
// ****************************
.sec-conexiones {
	overflow: hidden;
	background: $black;

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		margin-bottom: 4.7rem;
		text-align: center;
		text-transform: uppercase;
	}
	.us-desc {
		text-align: left;
		position: absolute;
		top: 410px;
		padding: 0 10px;
		.subtitle-line {
			margin-bottom: 0;
			padding-bottom: 0;
			line-height: 0.9;
		}
		p {
			font-size: 21px;
		}
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			position: initial;
		}
	}
	.us-icons {
		margin-top: 120px;
		.d-flex {
			div {
				@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
					margin-top: 2rem;
				}
			}
		}
	}
}

.quienes-dos {
	background: url(#{$_image-path}/bkg/salsa-chile-bkg.jpg) no-repeat;
	background-size: cover;
	min-height: 783px;
	padding-top: 12rem;
	.on-item {
		padding: 0;
		margin-bottom: 4rem;
	}
	.subtitle-line {
		padding-left: 6rem;
		padding-bottom: 0;
		color: $white;
		margin-top: 2rem;
		font-weight: 600;
		text-transform: inherit !important;
		font-size: 40px;
		&:first-of-type {
			background: $brand-secondary;
			padding: 0.5rem 1rem 0.5rem 6rem;
		}
	}
}

.quienes-text {
	display: flex;
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
	img {
		max-width: 454px;
	}
	.quien-desc {
		color: $white;
		text-align: left;
		padding-right: 3rem;
		padding-left: 1rem;
		&.quienes-alt {
			text-align: right;
			.subtitle-line {
				padding-left: 0;
				padding-right: 4rem;
			}
		}
	}
	.slide {
		padding: 1rem 0 2rem;
		margin: 2rem 0;
	}
	.subtitle-line {
		padding-left: 4rem;
		margin-top: 4rem;
		color: $brand-secondary;
		font-weight: normal;
	}
}
.carousel-item {
	.subtitle-line-dos {
		font-size: 28px;
	}
}

.subtitle-line {
	color: $brand-primary;
	font-weight: 600;
	font-size: 36px;
	font-weight: bold;
	text-transform: uppercase;
}
.subtitle-line-dos {
	padding-bottom: 0;
	color: $brand-primary;
	margin-top: 2rem;
	font-weight: 500;
	font-size: 40px;
}
.button {
	background: transparent !important;
	border: 2px solid $brand-secondary;
	color: $brand-secondary;
	box-shadow: none;
	font-family: "Roboto Slab", serif;
	&:hover {
		border: 2px solid $brand-secondary;
		color: $white;
		background: $brand-secondary;
	}
}

.button.white-butt {
	background: transparent;
	border: 2px solid $white;
	color: $white;
	box-shadow: none;
	font-family: "Roboto Slab", serif;
	font-size: 13px;
	&:hover {
		border: 2px solid $brand-secondary;
		color: $white;
		background: $brand-secondary;
	}
}

.text-center {
	text-align: center;
}

.wrapper-animation {
	justify-content: center;
	margin: 0 auto 3.7rem auto;

	.d-flex {
		justify-content: center;
		position: relative;
		align-items: flex-end;
		justify-content: space-around;
	}

	h5 {
		line-height: 1;
		color: $brand-secondary;
		font-weight: 600;
		font-size: 38px;
		letter-spacing: -0.92px;
		order: 2;
		padding-top: 1rem;
		text-align: center;
		text-transform: uppercase;
	}
}

.mv-and {
	@include animate-elm();
	bottom: 2000px;
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;

	svg {
		height: 11.5625rem !important;
		margin: auto;
		width: 11.5625rem !important;
	}

	&:nth-child(1) {
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding-right: 0;
		}
	}

	&:nth-child(2) {
		-webkit-animation-delay: 0.7s;
		animation-delay: 0.7s;
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin: 2rem auto;
			padding-right: 0;
		}
	}

	&:nth-child(3) {
		-webkit-animation-delay: 1.4s;
		animation-delay: 1.4s;
	}

	&.on-item {
		-webkit-animation-name: bounce-in-up;
		animation-name: bounce-in-up;
		bottom: 0;
		position: relative;

		&:nth-child(1) {
			&::after {
				-webkit-animation: 1.3s ease 0s normal forwards 1 fadein;
				animation: 1.3s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}

		&:nth-child(2) {
			&::after {
				-webkit-animation: 1.7s ease 0s normal forwards 1 fadein;
				animation: 1.7s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}
	}
}

// ****************************
// sec-servicios
// ****************************
.sec-servicios {
	overflow: hidden;
	background: $white;

	.col-lg-3 {
		min-height: 360px;
		display: flex;
		align-items: flex-end;
		padding: 1rem;
		&.h-one {
			background: url(#{$_image-path}/bkg/ofera-back-1.jpg) no-repeat;
			background-size: cover;
		}
		&.h-two {
			background: url(#{$_image-path}/bkg/ofera-back-2.jpg) no-repeat;
			background-size: cover;
		}
		&.h-three {
			background: url(#{$_image-path}/bkg/ofera-back-3.jpg) no-repeat;
			background-size: cover;
		}
		&.h-four {
			background: url(#{$_image-path}/bkg/ofera-back-4.jpg) no-repeat;
			background-size: cover;
		}
		p {
			color: $white;
			text-align: left;
			font-size: $font-size-25;
			font-weight: 600;
		}
		a {
			color: $white;
			text-align: right;
			width: 100%;
			display: block;
			font-size: $font-size-20;
			font-weight: 100;
			padding-right: 3rem;
			background: url(#{$_image-path}/bkg/arrow-r.png) no-repeat right center;
		}
	}

	.col-lg-6.back-img {
		background: url(#{$_image-path}/bkg/fedi-servicios.jpg) no-repeat center bottom;
		background-size: cover;
	}

	.container-fluid {
		max-width: 100%;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		text-align: center;
		text-transform: uppercase;
	}

	.row {
		padding-top: 1.8rem;
		position: relative;
	}

	h3 {
		@include txt-font-30($section-subtitle, 800);
		background: url(#{$_image-path}/bkg/ondas-title-pink.svg) no-repeat center bottom;
		display: inline-flex;
		letter-spacing: -0.83px;
		line-height: 38px;
		margin-bottom: 0.8rem;
		padding-bottom: 1.28rem;
		text-align: center;
		text-transform: uppercase;
	}

	p {
		@include txt-font-18($text-gral, 500);
		letter-spacing: -0.5px;
		line-height: 21px;
		margin: 0 auto 0.5rem;
		position: relative;
		text-align: center;
		z-index: 5;
	}

	.button {
		margin-top: 1.5rem;
	}
}

// ****************************
// sec-testimonios
// ****************************
.sec-testimonios {
	background: $bg-testimonios;
	height: 456px;
	margin-top: 4.5rem;
	padding-top: 4.375rem;
	position: relative;

	&::before {
		@include circle-lateral();
		left: 0;
		transform: translate(-50%, -50%);
	}

	&::after {
		@include circle-lateral();
		right: 0;
		transform: translate(50%, -50%);
	}

	.swiper-pagination {
		bottom: 90px;
		pointer-events: fill;
	}

	.container-fluid {
		height: 100%;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.swiper-container {
		height: 456px;
		pointer-events: none;
	}

	.swiper-pagination-bullet {
		background: $bg-pink-1;
		border: 0;
		height: 0.7rem;
		margin: 0.3rem;
		opacity: 0.8;
		width: 0.7rem;

		&.swiper-pagination-bullet-active  {
			opacity: 1;
		}
	}

	h3 {
		@include txt-font-50($brand-primary, $font-size-50);
		@include line-subtitle();
		display: inline-flex;
		font-weight: 900;
		letter-spacing: -1.39px;
		line-height: 63px;
		margin: 0 auto 2.5rem;
		text-align: center;
		text-transform: uppercase;
		word-break: break-all;
	}

	p {
		@include txt-font-16($text-gral);
		border-bottom: solid 1px $brand-accent;
		font-weight: 500;
		letter-spacing: -0.47px;
		margin-bottom: 1.3rem;
		padding-bottom: 1.5625rem;
		width: 60.5%;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			width: 100%;
		}
	}

	h6 {
		@include txt-font-16($text-gral);
		font-style: italic;
	}
}

.sec-home {
	.sec-conexiones {
		.container {
			max-width: 550px;
			text-align: center;
			h1 {
				font-size: 40px;
				color: $white;
			}
			p{
				color: $white-2;
			}
		}
	}

	.form-field {
		margin-bottom: 1rem;
		option {
			color: $light-black;
		}
	}

	.sec-page-top {		
		padding: 0;
		height: 740px;
		position: relative;
		svg {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 10vw;
			/* set height to pixels if you want angle to change with screen width */
		}
		.container {
			//background: url(#{$_image-path}/banners/poster-10.jpg) no-repeat center top;
			background: rgba(0,0,0,.7);
			background-size: contain;
			width: 460px;
			height: 100%;
			padding-top: 8rem;
			-webkit-box-shadow: 2px 0px 13px 1px rgba(0,0,0,0.63);
-moz-box-shadow: 2px 0px 13px 1px rgba(0,0,0,0.63);
box-shadow: 2px 0px 13px 1px rgba(0,0,0,0.63);
		}
	}

	.sec-servicios {
		h3 {
			@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
				margin-top: 30px;
			}
		}
	}
}

.carousel-control-next-icon {
	background-image: url(#{$_image-path}/bkg/derecha.svg) !important;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
	width: 35px !important;
	height: 33px !important;
}
.carousel-control-next,
.carousel-control-prev {
	width: 5% !important;
}

.carousel-item.active.brand-link {
	display: flex;
	column-gap: 2rem;
	align-items: center;
	#anim {
		flex: 2;
	}
	.subtitle-line-dos {
		flex: 2;
	}
}
