.sec-candidatos-top {
	@include bg-top-page('andari-top-candidatos-2.jpg');
 }


.pag-candidatos {

	.intro-default h2 { margin-bottom: 1rem; }

	.sec-page-top {

		h4 {
			width: 80%;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) { width: 100%; }

		}

		p {
			margin-left: 0; width: 32.4375rem;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) { width: 100%; }

		}

	}
}


.sec-mercado-laboral {
	background: $white-3; padding: 2.8125rem 0;

	.content-text {

		p {

			&:first-of-type { background: url(#{$_image-path}/bkg/linea2-blue.svg) no-repeat left bottom; padding-bottom: 2.2rem; }

			&:last-of-type { margin: 0; }

		}
	}

}

.sec-vidalaboral {
	margin: 5.1875rem 0 7.5rem;

	.txt-pink { @include txt-font-30($brand-accent, 800); letter-spacing: -0.94px; text-align: center; width: 80%; }

	h3 { @include txt-font-30($section-subtitle, 800); font-family: $font-family-heading; letter-spacing: -0.83px; margin: 5rem auto 4rem; text-align: center; text-transform: uppercase; width: 50%; }

	p {
		@include txt-font-18($text-gral, 500); letter-spacing: -0.5px; line-height: 21px; margin: 0 auto 0.5rem; position: relative; text-align: center; z-index: 5;

		strong { font-weight: 800; }

	}
}

.sec-candidatos-servicios {
	margin-top: 3.7rem;

	li {

		&:nth-child(1) {
			&::before { @include iconli(); background: url(#{$_image-path}/bkg/servicio-vocacional.svg) no-repeat center; }
		}

		&:nth-child(2) {
			&::before { @include iconli(); background: url(#{$_image-path}/bkg/servicio-empresa-coachingliderazgo.svg) no-repeat center; }

		}

		&:nth-child(3) {
			&::before { @include iconli(); background: url(#{$_image-path}/bkg/servicio-candidatos-coaching.svg) no-repeat center; }
		}
	}

	// h3 { margin-left: 2.5rem; }

	ul { display: flex; flex-direction: column; height: 100%; justify-content: center; }

}
