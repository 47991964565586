.sec-page-top.sec-como-top {
	h2 {
		text-align: center;
		margin-top: 26rem;
	}
}
.fancy-sub {
	font-size: 23px;
	color: $color-primary;
	font-family: "Roboto Slab", serif;
	font-weight: 700;
	text-transform: uppercase;
}
.sec-page-top {
	overflow: hidden;

	p {
		@include txt-font-20($text-gral, normal, $font-size-20);
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation: 2s ease 0s normal forwards 1 fadein;
		animation: 2s ease 0s normal forwards 1 fadein;
		opacity: 1;
		letter-spacing: -0.58px;
		text-align: center;

		strong {
			font-weight: 700;
		}
	}

	.slogan {
		color: $color-primary;
		font-size: 20px;
		margin-top: 0;
		font-weight: 300;
	}

	h2 {
		@include line-subtitle();
		font-size: $font-size-48;
		color: $color-primary;
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation-name: bounce-in-up;
		animation-name: bounce-in-up;
		letter-spacing: -1.39px;
		text-align: left;
		margin-top: 9rem;
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			@include txt-font-40($black);
			margin-top: 3rem !important;
		}
	}

	.button {
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin-bottom: 3rem;
		}
	}

	h4 {
		font-size: $font-size-20;
		color: $white;
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
		animation: 1.5s ease 0s normal forwards 1 fadein;
		opacity: 1;
		font-weight: 600;
		letter-spacing: -0.83px;
		margin-top: 1rem;
		text-align: left;
		text-transform: uppercase;
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			color: $black;
			margin-bottom: 3rem;
		}
	}
}

.sec-empresas-top,
.sec-aduanas-top {
	h2 {
		&::after {
			background: $brand-accent;
			bottom: 12px;
			content: " ";
			display: block;
			height: 6px;
			left: 0;
			position: absolute;
			right: 0;
			width: 115px;
		}
	}
}
.sec-aduanas-top {
	h4 {
		max-width: 30%;
		font-weight: 600;
		font-size: 20px;
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			max-width: 100%;
		}
	}
}

.sec-map-top {
	h2 {
		@include line-subtitle();
		@include txt-font-50($brand-secondary, $font-size-45);
		font-size: $font-size-45;
		display: inline-flex;
		font-weight: 600;
		letter-spacing: -1.39px;
		margin: 3rem auto;
		text-transform: uppercase;
	}
}
.sec-servicios {
	.subtitle-line {
		font-weight: 600;
		@include txt-font-50($brand-secondary, $font-size-45);
		font-size: $font-size-45;
	}
}

.sec-ayuda-top,
.sec-servicios {
	h2 {
		@include line-subtitle();
		@include txt-font-50($brand-secondary, $font-size-35);
		font-size: $font-size-35;
		font-weight: 500;
		line-height: 2.6rem;
		letter-spacing: 1px;
		margin: 0 auto 3rem;
		text-transform: uppercase;
	}
}

.sec-proj {
	.sub-second {
		margin: 1rem 0 2rem;
	}
}

.sec-servicios {
	h2 {
		margin-top: 3rem;
		padding-top: 0;
	}
}

#carouselClients {
	background: url(#{$_image-path}/bkg/salsa-slide-bkg.jpg) no-repeat center;
	background-size: cover;
	min-height: 219px;
	display: flex;
	align-items: center;
}

#clientes {
	background: $black url(#{$_image-path}/bkg/salsa-right.jpg) no-repeat right center;
	background-size: cover;
	.col-lg {
		min-height: 460px;
		.container {
			display: flex;
			padding: 0;
			max-width: 1100px;
			.cli-left {
				background: $black;
				background-size: cover;
				min-height: 460px;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				padding-right: 9.5rem;
				padding-bottom: 2rem;
			}
			.cli-right {
				min-height: 460px;
				width: 50%;
				display: flex;
				align-items: flex-end;
				justify-content: flex-start;
				padding-left: 9.5rem;
				padding-bottom: 2rem;
			}
		}
	}
}

.sec-equipo {
	background: rgb(255, 26, 65);
	background: linear-gradient(180deg, rgba(255, 26, 65, 1) 60%, rgba(255, 26, 65, 0) 60%);
	.col-lg-8 {
		margin-left: auto;
	}
	.subtitle-line {
		font-weight: 600;
		text-align: right;
		padding-right: 4rem;
		margin-bottom: 2rem;
		margin-top: 2rem;
		color: $white;
	}
	.row {
		.col-lg-4 {
			padding-right: 6px;
			padding-left: 6px;
			margin-bottom: 12px;
		}
	}
}

.sec-promosiones {
	background: $black;
	padding: 4rem 0 2rem;
	position: relative;
	background-size: cover;
	min-height: 460px;
	position: relative;
	.container-fluid {
		width: 100%;
		max-width: 100%;
		.col-lg-8 {
			margin: auto;
			h1 {
				text-align: center;
				font-size: 40px;
			}
		}
	}
	.row {
		align-items: center;
	}
	h4,
	h3,
	p {
		color: $white;
	}
	h4 {
		font-weight: 600;
		font-size: 24px;
		text-transform: uppercase;
	}
	h3 {
		border-top: 3px solid $brand-secondary;
		padding-top: 1rem;
		margin-top: 0.5rem;
		margin-left: 0;
		text-transform: uppercase;
		font-size: 28px;
		max-width: 260px;
		font-weight: 100;
		b {
			font-weight: 700;
		}
	}
	p {
		font-size: 16px;
	}
}
.sec-clientes {
	text-align: left;
	h4,
	h3,
	p {
		color: $white;
	}
	h4 {
		font-weight: 600;
		font-size: 24px;
		text-transform: uppercase;
	}
	h3 {
		border-top: 3px solid $brand-secondary;
		padding-top: 1rem;
		margin-top: 0.5rem;
		margin-left: 0;
		text-transform: uppercase;
		font-size: 28px;
		max-width: 260px;
		font-weight: 100;
		b {
			font-weight: 700;
		}
	}
	p {
		font-size: 16px;
	}
}
.sec-como-funciona {
	.sec-internet.alt {
		.col-lg {
			display: flex;
			column-gap: 10rem;
			align-items: flex-start;
			padding: 4rem 0;
			.subtitle-line {
				margin-top: 0;
				padding-top: 0;
				margin-bottom: 4rem;
			}
			div {
				text-align: center;
				&:first-of-type {
					flex: 0.6;
				}
				&:last-of-type {
					flex: 1;
				}
				.sub-first {
					margin-bottom: 6rem;
				}
				.sub-last {
					margin-bottom: 0;
				}
				.gray-menu {
					font-size: 18px;
					color: $gray-one;
					padding-left: 0;
					list-style: none;
					margin-bottom: 9rem;
					li {
						&::before {
							content: none;
						}
					}
				}
				.pink-menu {
					font-size: 24px;
					color: $brand-primary;
					font-weight: 800;
					padding-left: 0;
					font-family: "Roboto Slab", serif;
					li {
						&::before {
							content: none;
						}
					}
				}
			}
		}
	}
	.sec-internet {
		.col-lg {
			display: flex;
			column-gap: 10rem;
			align-items: flex-start;
			padding: 4rem 0;
			.subtitle-line {
				margin-top: 0;
				padding-top: 0;
				margin-bottom: 4rem;
			}
			div {
				text-align: left;
				&:first-of-type {
					flex: 0.6;
				}
				&:last-of-type {
					flex: 1;
				}
				.sub-first {
					margin-bottom: 6rem;
					text-transform: uppercase;
				}
				.sub-last {
					margin-bottom: 0;
				}
				.gray-menu {
					font-size: 18px;
					color: $gray-one;
					padding-left: 0;
					list-style: none;
					margin-bottom: 9rem;
					li {
						&::before {
							content: none;
						}
					}
				}
				.pink-menu {
					font-size: 24px;
					color: $brand-primary;
					font-weight: 800;
					padding-left: 0;
					li {
						&::before {
							content: none;
						}
					}
				}
			}
		}
		.pager {
			margin-top: 6rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			a {
				color: $brand-primary;
				font-size: 19px;
				font-family: "Roboto Slab", serif;
			}
			h2 {
				font-size: 29px;
				text-transform: uppercase;
				color: $brand-secondary;
				font-weight: normal;
			}
		}
	}
	#ventajas {
		position: relative;
		padding-bottom: 1px;
		background: url(#{$_image-path}/bkg/salsa-proj-bkg-1.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 680px;
	}
	#ventajas.sec-clientes-two {
		position: relative;
		padding-bottom: 1px;
		background: url(#{$_image-path}/bkg/salsa-proj-bkg-2.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 680px;
		margin-top: 4rem;
	}
	.img-legend {
		.container {
			display: flex;
			justify-content: flex-end;
			p {
				margin-top: 0.5rem;
				margin-bottom: 1rem;
				margin-right: 0;
			}
		}
	}
	.four-img {
		margin-top: 4rem;
		div {
			display: flex;
			column-gap: 1rem;
			margin-bottom: 1rem;
			img {
				width: 100%;
			}
		}
	}
}

.sec-clientes {
	.container-fluid {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	.row {
		align-items: center;
	}
	.col-lg {
		margin-left: auto;
		margin-right: auto;
		color: $white;
		h2 {
			color: $white;
		}
		p {
			margin-top: 0;
		}
	}
	.row {
		max-width: 1100px;
		margin-right: auto;
		margin-left: auto;
		margin-top: -10rem;
	}
	.col-lg-3 {
		background: $white url(#{$_image-path}/bkg/client-dots.png) 230px 10px no-repeat;
		text-align: left;
		margin-right: 3rem;
		margin-top: 1rem;
		border-radius: 10px;
		padding: 0 1.6rem 0;
		flex: 0 0 30%;
		max-width: 30%;
		-webkit-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		&:last-child {
			margin-right: 0;
		}

		.header-cli {
			display: flex;
			margin-left: -4rem;
			height: 68px;
			img {
				width: 87px;
				height: 87px;
				position: absolute;
				top: -10px;
			}
			div {
				p {
					margin-bottom: 0;
					padding-left: 6rem;
					&:last-of-type {
						margin-top: 0;
					}
					&:first-of-type {
						margin-top: 1.5rem;
						color: $color-primary;
						font-size: $font-size-20;
					}
				}
			}
		}
	}
}

.sec-ventajas {
	padding: 3rem 0 0;
	.col-lg-6 {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem;
	}
	.col-lg-3 {
		margin-right: 1rem;
		flex: 0 0 25%;
		max-width: 23%;
		&:nth-last-of-type {
			margin-right: 0;
		}
		h4 {
			font-size: $font-size-20;
			font-weight: 100;
		}
	}
}

.subtitle-line {
	@include line-subtitle();
	color: $color-primary;
	font-size: $font-size-48;
	display: inline-flex;
	letter-spacing: -1.39px;
	margin: auto;
	padding: 1.25rem 0 0.8rem;
}

.bg-primary {
	background: $bg-pink-1;
}

.bg-textura {
	p:first-of-type {
		width: 70%;
		margin-bottom: 3rem;
	}
}

.sec-banner-fullwidth {
	h2 {
		@include line-subtitle();
		color: $brand-secondary;
		font-size: $font-size-45;
		display: inline-flex;
		font-weight: 600;
		letter-spacing: -1.39px;
		margin: auto;
		text-transform: uppercase;
		padding: 1.25rem 0 0.8rem;
	}

	&.bg-primary {
		background: $color-primary url(#{$_image-path}/bkg/fedi-transp.jpg) no-repeat center bottom;
		background-size: cover;
		padding: 2.7rem 0;
		margin: 3rem 0;

		p {
			@include txt-font-20($white, 700);
			letter-spacing: -0.94px;
		}

		h2 {
			@include txt-font-40($white, 700);
			text-transform: initial;
		}
	}

	&.bg-primary-ad {
		background: $color-primary url(#{$_image-path}/bkg/fedi-adu-blue.jpg) no-repeat center bottom;
		background-size: cover;
		padding: 2.7rem 0 3.7rem 0;
		margin: 3rem 0;

		p {
			@include txt-font-20($white, 700);
			letter-spacing: -0.94px;
		}

		h2 {
			color: $white;
		}
	}
}
.bg-textura {
	padding: 5rem 0;
}

.bg-blue-dark {
	background-color: $brand-primary;
}

.intro-default {
	background: $white-3;
}

.sec-reto-top,
.empresas-servicios-info {
	.quienes-text {
		margin: 2.5rem 0;
	}
	.row {
		justify-content: space-around;
		.on-item {
			max-width: 50%;
			padding: 0;
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				max-width: 100%;
			}
			&:first-of-type {
				padding-right: 38px;
			}
			&:last-of-type {
				padding-left: 38px;
			}
		}
		.serv-desc {
			text-align: left;
			.subtitle-line {
				margin-bottom: 0;
				padding-bottom: 0;
				line-height: 0.9;
				text-transform: initial;
				font-size: $font-size-40;
			}
		}
	}
}
.empresas-servicios-info {
	padding: 5rem 0;
	.on-item {
		&:last-of-type {
			img {
				padding-top: 28px;
			}
			.serv-desc {
				p {
					margin-top: 3.8rem;
				}
			}
		}
		&:first-of-type {
			.serv-desc {
				p {
					margin-top: 2rem;
				}
			}
		}
	}
}

.sec-metodologia {
	.container-fluid {
		max-width: 100%;
		.col-lg-6 {
			background: $bg-gray;
			text-align: left;
			padding: 2rem 2rem 2rem 8rem;
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				max-width: 100%;
				width: 100%;
				padding: 2rem;
			}
			.serv-list {
				text-align: left;
				margin: initial;
				padding-left: 1em;
				li {
					margin-bottom: 14px;
					list-style: none;
					font-size: 21px;
					&::before {
						content: "\2022";
						color: $brand-accent;
						font-weight: bold;
						display: inline-block;
						width: 1em;
						margin-left: 1em;
					}
				}
			}
			h3 {
				color: $color-secondary;
				margin-bottom: 1rem;
			}
		}
		.back-img {
			background: url(#{$_image-path}/bkg/fedi-servi-image.jpg) no-repeat center bottom;
			background-size: cover;
		}
	}
}
.sec-como-funciona {
	.sec-clientes {
		color: $white;
		h3 {
			font-size: 21px;
		}
	}
}

.sec-internet {
	.subtitle-line {
		color: $brand-primary;
		font-weight: 600;
	}
	h3 {
		color: $brand-secondary;
		font-weight: 100;
		font-size: 32px;
	}
	.col-lg-8 {
		margin: 4rem auto 6rem;
	}
	.col-lg-2 {
		img {
			width: 77px;
			height: 77px;
		}
	}
	.col-lg-4 {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 4rem;
		img {
			min-height: 78px;
		}
		.serv-title {
			color: $brand-primary;
			font-weight: 700;
			font-size: 20px;
			border-bottom: 3px solid $brand-secondary;
			padding-bottom: 0.5rem;
			margin-bottom: 1rem;
			margin-top: 1rem;
		}
		p {
			margin-bottom: 0;
		}
	}
}
.sec-somos {
	.sec-servicio.alt {
		position: relative;
		padding-bottom: 4rem;
	}
	#ventajas {
		position: relative;
		padding-bottom: 1px;
		background: $color-primary;
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 25px;
			height: 200px;
			background: radial-gradient(closest-side, $white, $white 63%, $color-primary 65%);
			background-size: 146px 90px;
			background-position: 0px 169px;
			background-repeat: repeat-x;
		}
		&:after {
			background: radial-gradient(closest-side, $color-primary, $color-primary 60%, $white 63%);
			background-size: 146px 90px;
			background-position: 73px -64px;
			background-repeat: repeat-x;
			bottom: -174px;
		}
		.col-lg-6 {
			margin: 4rem auto 0;
			font-size: 20px;
		}
	}
}
.sec-servicio {
	.col-lg-6 {
		margin: 4rem auto;
		h2 {
			padding-bottom: 0;
		}
		h4 {
			font-size: $font-size-25;
			font-weight: 100;
			padding-top: 0;
		}
	}
	.col-lg-3 {
		margin-right: 1rem;
		flex: 0 0 25%;
		max-width: 23%;
		&:nth-last-of-type {
			margin-right: 0;
		}
		h4 {
			font-size: $font-size-20;
			font-weight: 100;
		}
	}
}

.sec-blog {
	main {
		&.content {
			background: $white;
			padding: 0 0 4rem;

			.blog-header {
				padding: 4rem 26rem 8rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				background: $color-primary;
				color: $white;

				position: relative;
				&:before,
				&:after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 25px;
					height: 200px;
					background: radial-gradient(closest-side, $white, $white 63%, $color-primary 65%);
					background-size: 146px 90px;
					background-position: 0px 169px;
					background-repeat: repeat-x;
				}
				&:after {
					background: radial-gradient(closest-side, $color-primary, $color-primary 60%, $white 63%);
					background-size: 146px 90px;
					background-position: 73px -64px;
					background-repeat: repeat-x;
					bottom: -174px;
				}

				.search-wrapper {
					width: 70%;

					.search-input {
						border: 1px solid $white;
						border-radius: 100px;
						background: url(#{$_image-path}/bkg/sky-search.svg);
						background-repeat: no-repeat;
						background-position-x: 97%;
						padding: 0 2rem;
						background-position-y: 6px;
					}
				}

				h2 {
					text-transform: uppercase;
					margin-bottom: 2rem;
				}
			}

			.posts-item-dest {
				display: flex;
				position: absolute;
				top: -5rem;
				max-height: 340px;

				.dest-text {
					flex: 1;
					padding: 2rem;
					background: $contact-green;

					a {
						color: $black;
						margin-bottom: 2rem;
						display: block;
						font-size: 28px;
						font-weight: 700;
					}

					p {
						color: $white;
					}
				}

				.dest-im {
					flex: 1;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.content-wrapper {
				max-width: 1100px;
				margin: auto;
				display: flex;
				padding: 18rem 0 4rem;
				position: relative;

				#listing {
					width: 70%;
					display: grid;
					grid-template-columns: 4fr 4fr;
					column-gap: 1rem;

					.list-item {
						border-bottom: 4px solid $color-primary;
						margin-bottom: 2rem;
						padding-bottom: 1rem;

						.p-name {
							margin: 1rem 0 2rem;

							.list-blog-date {
								display: none;
							}

							a {
								color: $black;
								font-size: 20px;
							}
						}

						.p-summary {
							a {
								color: $contact-green;
							}
						}

						.tags {
							margin-top: 0.5rem;
							display: block;

							a {
								color: $contact-green;
							}
						}
					}
				}

				#sidebar {
					width: 30%;
					margin-left: 2rem;

					.search-wrapper {
						display: none;
					}

					.b-categories {
						padding: 1rem;
						background-color: $bg-gray;
						margin-bottom: 2rem;

						h4 {
							border-bottom: 1px solid $color-primary;
							padding-bottom: 1rem;
							margin-bottom: 1rem;
							font-size: 20px;
							color: $black;
						}

						.tags {
							display: block;

							a {
								display: block;
								color: $black;
							}
						}
					}

					.recent-post {
						background: $color-primary;
						padding: 1rem;

						h4 {
							color: $white;
							margin-bottom: 2rem;
							font-size: 20px;
						}

						ul {
							padding-left: 0;

							li {
								&::before {
									content: none;
								}

								list-style: none;
								border-top: 1px solid $white;
								padding: 1rem 0;
								display: flex;

								.imagencita {
									flex: 1;

									img {
										width: 124px;
										height: 72px;
									}
								}

								a {
									color: $white;
									font-weight: 100;
									flex: 1;
									margin-right: 1rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

.blog-content-item {
	background: $white;

	#item {
		max-width: 1100px;
		margin: auto;
		display: flex;
		padding: 2rem 0;
		position: relative;

		.h-entry {
			flex: 1;

			.list-blog-header {
				padding-top: 650px;

				img {
					position: absolute;
					width: 100%;
					top: 3rem;
					max-height: 600px;
					object-fit: cover;
				}
			}

			a {
				color: $black;
				display: block;
				margin: 1rem 0;
				font-size: 20px;
			}

			hr {
				border: 2px solid $color-primary;
				width: 10%;
				margin-left: 0;
				margin-top: 2rem;
				margin-bottom: 2rem;
			}

			h4 {
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					font-size: 12px;
					color: $light-black;
				}
			}
		}

		#sidebar {
			width: 30%;
			margin-left: 2rem;
			margin-top: 650px;

			.search-wrapper {
				margin-bottom: 2rem;

				.search-input {
					border: 1px solid $black;
					border-radius: 100px;
					background: url(#{$_image-path}/bkg/sky-search.svg);
					background-repeat: no-repeat;
					background-position-x: 97%;
					padding: 0 2rem;
					background-position-y: 6px;
					color: $contact-green;
				}
			}

			.b-categories {
				padding: 1rem;
				background-color: $bg-gray;
				margin-bottom: 2rem;
				color: $black;

				h4 {
					border-bottom: 1px solid $color-primary;
					padding-bottom: 1rem;
					margin-bottom: 1rem;
					font-size: 20px;
				}

				.tags {
					display: block;

					a {
						display: block;
						color: $black;
					}
				}
			}

			.recent-post {
				background: $color-primary;
				padding: 1rem;

				h4 {
					color: $white;
					margin-bottom: 2rem;
					font-size: 20px;
				}

				ul {
					padding-left: 0;

					li {
						&::before {
							content: none;
						}

						list-style: none;
						border-top: 1px solid $white;
						padding: 1rem 0;
						display: flex;

						.imagencita {
							flex: 1;

							img {
								width: 124px;
								height: 72px;
							}
						}

						a {
							color: $white;
							font-weight: 100;
							flex: 1;
							margin-right: 1rem;
						}
					}
				}
			}
		}
	}
}

#ventajas {
	.row {
		align-items: flex-start;
		max-width: 1280px;
		margin-top: 4rem;
	}
}
