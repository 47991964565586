/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */




// vars
$_footer-mobile-breakpoint : 'md' !default;
$_footer-logo-image        : false !default;
$_footer-logo-xs-w         : 10rem !default;
$_footer-logo-xs-h         : 10rem !default;
$_footer-logo-xl-w         : $_footer-logo-xs-w !default;
$_footer-logo-xl-h         : $_footer-logo-xs-h !default;




// Logo
// **************************************************


@if $_footer-logo-image {
	.footer-logo-link {
		@include hide-text; background: url(#{$_image-path}/#{$_footer-logo-image}) center no-repeat; background-size: contain; display: block; height: $_footer-logo-xs-h; width: $_footer-logo-xs-w;
		@media all and (min-width: map-get($grid-breakpoints, $_footer-mobile-breakpoint)) { height: $_footer-logo-xl-h; width: $_footer-logo-xl-w }
	}
}
