.sec-empresas-top {
	@include bg-top-page('fedi-servicios-pg.jpg');
}
.sec-aduanas-top{
	@include bg-top-page('fedi-aduanas.jpg');
}


.pag-empresas {

	.sec-page-top {
		padding: 0;

		p {
			margin-left: 0; width: 26.5rem;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				width: 100%;
			}

		}

	}

	.intro-default {
		padding: 5.375rem 0;

		p:last-of-type { background-image: none; }

		 h2 {
			 background: url(#{$_image-path}/bkg/linea2-blue.svg) no-repeat left bottom; padding-bottom: 2.2rem; text-transform: uppercase;

			 @media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) { background-position: center bottom; }

		 }
	}

}

.sec-metodologia {
	.subtitle { @include txt-font-30($section-subtitle, 800); font-family: $font-family-heading; letter-spacing: -0.83px; margin: 5rem auto 4rem; text-align: center; text-transform: uppercase; width: 50%; }
}


.empresas-servicios-info {

	li {

		&:nth-child(1) {
			&::before { @include iconli(); background: url(#{$_image-path}/bkg/servicio-empresa-seleccion.svg) no-repeat center; background-size: contain; }
		}

		&:nth-child(2) {
			&::before { @include iconli(); background: url(#{$_image-path}/bkg/servicio-empresa-nomina.svg) no-repeat center; background-size: contain; }
		}

		&:nth-child(3) {
			&::before { @include iconli(); background: url(#{$_image-path}/bkg/servicio-empresa-coaching.svg) no-repeat center; background-size: contain; }
		}

		&:nth-child(4) {
			&::before { @include iconli(); background: url(#{$_image-path}/bkg/servicio-empresa-outplacement.svg) no-repeat center; background-size: contain; }
		}
	}


}
