// // ******************
// // contacto
// // ******************

.onload {
	.contact-form {
		&::after { content: ' '; background: url(#{$_image-path}/bkg/ajax-loader.gif) no-repeat center center; display: block; height: 40px; width: 40px; }
	 }
}

.content-form {
	padding: 3.7rem 0 3.2rem; margin-left: auto; margin-right: auto;

	textarea { height: 4.8125rem; }

}

.msg-succes { @include txt-font-28($white, 800); font-weight: 800; letter-spacing: -0.5px; margin: 2rem auto; text-align: center; }

.title-contact {
	@include txt-font-50($brand-primary); font-weight: 900; letter-spacing: -1.39px; line-height: 63px; margin: auto; padding: 0.7rem 0; text-transform: uppercase; word-break: break-all;

	&::before { background: $lines-titles-2; content: ' '; display: block; height: 8px; left: 0; position: absolute; right: 0; top: 0; width: 75px; }

	&::after { background: $lines-titles-2; bottom: 0; content: ' '; display: block; height: 8px; left: 0; position: absolute; right: 0; width: 75px; }

}

.subtitle-contact { @include txt-font-28($white, 800); font-weight: 800; letter-spacing: -0.75px; margin: 2.3rem auto 0.5rem auto; position: relative; text-transform: uppercase; z-index: 10; }


.text-contact {text-align: center; @include txt-font-50($color-primary, 800); font-weight: 800; letter-spacing: -0.5px; margin: 0 auto 1.2rem; }


.contact-form {

	@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) { width: 358px; margin-left: auto; margin-right: auto;}

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) { width: 358px; margin-left: auto; margin-right: auto;}

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) { width: 100%; }

	input:not([type=radio]):not([type=checkbox]), select, textarea{margin-bottom: 1rem;}
	input:not([type=radio]):not([type=checkbox]), select, textarea{
		border-bottom: 2px solid $bg-gray; opacity: 0.7; color: $black;
		&:focus{ outline: none; background: transparent; color: $black;}
	}
	textarea{ min-height: 80px;
		&:focus{ outline: none;}
	}
	input:placeholder-shown:not(:focus)+label {margin-left: 10px; color: $black;}
	textarea:placeholder-shown:not(:focus)+label {margin-left: 10px; color: $black;}

}

.form-content { order: 2; }

.sec-page-top{
	background: $black;
}

.sec-contacto {
	position: relative; 

	.row { display: flex; background: $black;}


	.container-fluid { max-width: 100%; padding: 0; width: 100%; }


	.form-content { opacity: 1; background: transparent; padding: 0 0.625rem; }


	input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown),
	input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown),
	textarea.invalid:not(:focus):not(:placeholder-shown),
	textarea:invalid:not(:focus):not(:placeholder-shown) {
		border-bottom-color: $bg-inputs;
	}

	input:not([type="radio"]):not([type="checkbox"]) + label,
	select + label,
	textarea + label { top: -1rem; color: $black;}

	input:not([type=radio]):not([type=checkbox]):placeholder-shown:not(:focus)+label,
	textarea:placeholder-shown:not(:focus)+label
	{color: $black-light; font-weight: 100;}

	textarea,
	input,
	label { font-family: $font-family-base; font-weight: 500; letter-spacing: -0.19px; line-height: 24px; text-transform: uppercase; }

	.buttons { padding-left: 10px; width: 93%; text-align: center; 
		.button{padding: 8px 50px; background: $contact-green; border-color: $contact-green; 
			-webkit-box-shadow: 0px 4px 16px -5px rgba(115,204,129,1);
			-moz-box-shadow: 0px 4px 16px -5px rgba(115,204,129,1);
			box-shadow: 0px 4px 16px -5px rgba(115,204,129,1);
			&:hover{background: transparent; border-width: 2px; color:$white}
		}
	 }

}


.contact-top {
	width: 27.9375rem; margin-bottom: 2rem;

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		 width: 100%;
	}
}

.form-field.sectitle:nth-child(4n) {
    color: #000c56;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 40px;
}

.form-field.sectitle:nth-child(5n) {
    margin-bottom: 30px;
}
.sec-cont{
	.sec-contact-top{
		max-height: 600px;
		background-size: cover;
		min-height: 400px;
		.container-fluid{
			max-width: 100%;
		}
	}
}
.sec-cont{
	#contact{
		display: none;
	}	
}

#contacto-large{
	.contact-form {
		width: 100%;
		max-width: 1100px;
		.sectitle {
			background: $brand-primary;
			padding: 1rem;
			color: $white;
			font-weight: 100;
			font-size: 40px;
			text-transform: uppercase;
			font-family: 'Roboto Slab',serif;
			max-width: 432px;
			margin-bottom: 6rem;
		}
		label{
			color: $gray-three;
			font-family: 'Roboto Slab',serif;
			font-size: 25px;
			text-transform: inherit;
			font-weight: normal;
			span{
				color: $brand-primary;
			}
		}
		input:not([type=radio]):not([type=checkbox]), select, textarea {
			margin-bottom: 2rem;
			border-bottom: 2px solid $brand-secondary;
			opacity: 1;
			color: $gray-three;
		}
		textarea {
			min-height: 100px;
		}
		.buttons {
			padding-left: 0;
			width: 100%;
			text-align: right;
			.button{
				background-color: transparent;
				border: 3px solid $brand-secondary;
				padding: 0.8rem;
				color: $brand-secondary;
				box-shadow: none;
			}
		}
		.separator{
			margin: 2rem 0 4rem;
			color: $brand-primary;
			text-transform: uppercase;
			font-size: 40px;
			font-weight: bold;
			h1{
				border-bottom: 8px solid $brand-secondary;
				width: fit-content;
    margin-left: 0;
			}
		}
	}
}