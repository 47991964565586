.header {
	height: auto;
	padding: 0;
	z-index: 888;
	background-color: $black;
	-webkit-box-shadow: 0px 10px 16px -5px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 10px 16px -5px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 10px 16px -5px rgba(0, 0, 0, 0.3);

	.footer-sm {
		margin-left: 1rem;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			margin-right: 4rem;
			text-align: center;
		}

		.ico {
			@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
				height: 25px;
				width: 25px;

				&::before {
					position: relative;
				}
			}
		}
	}

	.footer-sm-fb::before {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			font-size: 1.2rem;
			top: -0.2rem;
		}
	}

	.footer-sm-tw::before {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			font-size: 1.2rem;
			top: -0.1rem;
		}
	}

	.footer-sm-in::before {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			font-size: 1.4rem;
			top: -0.2rem;
		}
	}

	.container-fluid {
		min-height: 4.5rem;
		z-index: 10;
	}

	@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
		#mnu-Search {
			margin: auto;
		}
	}

	.mnu {
		padding: 1rem 0;
		a {
			font-size: 14px;
			letter-spacing: 0.49px;
			padding-right: 3rem;
			color: $white;
			font-family: 'Roboto Slab', serif;

			@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
				padding: 0.5rem 0;
				color: $white;
				&.active {
					color: $white-2 !important;
					font-weight: 600;
				}
			}

			&:hover,
			&.active {
				font-size: 14px;
				color: $white-3;
				font-family: 'Roboto Slab', serif;
			}
		}
		&:last-child {
			a {
				padding-right: 0;
			}
		}
	}

	#mnu-Search {
		background: url(#{$_image-path}/bkg/search.svg) no-repeat right center;
		width: 30px;
		height: auto;
		a {
			font-size: 0;
		}
	}

	.site-logo {
		align-items: center;
		border: 0;
		display: flex;
		font-size: 0;
		height: 60px;
		justify-content: center;
		width: 12rem;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			width: 40%;
		}

		&:hover {
			color: $white;
			transition: none;
		}
	}

	.site-logo-link {
		border: 0;
		display: block;
		font-size: 0;
		height: 100%;
		padding: 0;
		background-position: left;
	}

	.main-menu {
		display: flex;
		flex: 1 1;
		justify-content: flex-end;
		width: 30.6875rem;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.menu-content {
			transition: all 0.55s ease-in-out;
			z-index: 5;

			@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
				left: -100vh;
			}
		}

		&.active {
			.menu-content {
				background: $color-secondary;
				left: 0;
				opacity: 1;
				pointer-events: all;
				text-align: center;
				top: 6rem;
				transform: translate(0, 0);
				width: 100%;
			}

			.menu-mobile {
				background: url(#{$_image-path}/bkg/uE006-delete.svg) no-repeat center;
				background-size: contain;
			}
		}
	}
	// mainmenu
}

.menu-mobile {
	cursor: pointer;
	color: $white;
	margin-top: 1rem;
	padding: 0;
	position: absolute;
	right: 2rem;
	text-align: center;
	top: 0.7rem;
	width: 2.5rem;

	&::before {
		display: none;
		padding: 0;

		@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
			display: block;
		}
	}
}
