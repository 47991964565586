@mixin animate-elm() {
	-webkit-animation-duration: 1s; -webkit-animation-fill-mode: both; animation-duration: 1s; animation-fill-mode: both;
}

.infinite { -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; }

.animated { @include animate-elm();  }

@-webkit-keyframes bounce-in-up {
	from,
	60%,
	75%,
	90%,
	to { -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

	from { opacity: 0; -webkit-transform: translate3d(0, 3000px, 0); transform: translate3d(0, 3000px, 0); }

	60% { opacity: 1; -webkit-transform: translate3d(0, -20px, 0); transform: translate3d(0, -20px, 0); }

	75% { -webkit-transform: translate3d(0, 10px, 0); transform: translate3d(0, 10px, 0); }

	90% { -webkit-transform: translate3d(0, -5px, 0); transform: translate3d(0, -5px, 0); }

	to { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
}

@keyframes bounce-in-up {
	from,
	60%,
	75%,
	90%,
	to { -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

	from { opacity: 0; -webkit-transform: translate3d(0, 3000px, 0); transform: translate3d(0, 3000px, 0); }

	60% { opacity: 1; -webkit-transform: translate3d(0, -20px, 0); transform: translate3d(0, -20px, 0); }

	75% { -webkit-transform: translate3d(0, 10px, 0); transform: translate3d(0, 10px, 0); }

	90% { -webkit-transform: translate3d(0, -5px, 0); transform: translate3d(0, -5px, 0); }

	to { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
}

.bounce-in-up { -webkit-animation-name: bounce-in-up; animation-name: bounce-in-up; }


@-webkit-keyframes fade-in {
	from { opacity: 0; }

	to { opacity: 1; }
}

@keyframes fade-in {
	from { opacity: 0; }

	to { opacity: 1; }
}

.fade-in { -webkit-animation-name: fade-in; animation-name: fade-in; }


@-webkit-keyframes shake {
	from,
	to { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

	10%,
	30%,
	50%,
	70%,
	90% { -webkit-transform: translate3d(-10px, 0, 0); transform: translate3d(-10px, 0, 0); }

	20%,
	40%,
	60%,
	80% { -webkit-transform: translate3d(10px, 0, 0); transform: translate3d(10px, 0, 0); }
}

@keyframes shake {
	from,
	to { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

	10%,
	30%,
	50%,
	70%,
	90% { -webkit-transform: translate3d(-10px, 0, 0); transform: translate3d(-10px, 0, 0); }

	20%,
	40%,
	60%,
	80% { -webkit-transform: translate3d(10px, 0, 0); transform: translate3d(10px, 0, 0); }
}

.shake { -webkit-animation-name: shake; animation-name: shake; }




@-webkit-keyframes flip-iny {
	from { -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; opacity: 0; -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg); transform: perspective(400px) rotate3d(0, 1, 0, 90deg); }

	40% {  -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg); transform: perspective(400px) rotate3d(0, 1, 0, -20deg); }

	60% { opacity: 1; -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg); transform: perspective(400px) rotate3d(0, 1, 0, 10deg); }

	80% { -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg); transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }

	to { -webkit-transform: perspective(400px); transform: perspective(400px); }
}

@keyframes flip-iny {
	from { -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; opacity: 0; -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg); transform: perspective(400px) rotate3d(0, 1, 0, 90deg); }

	40% { -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg); transform: perspective(400px) rotate3d(0, 1, 0, -20deg); }

	60% { opacity: 1; -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg); transform: perspective(400px) rotate3d(0, 1, 0, 10deg); }

	80% { -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg); transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }

	to { -webkit-transform: perspective(400px); transform: perspective(400px); }
}

.flip-iny { -webkit-animation-name: flip-iny; animation-name: flip-iny; -webkit-backface-visibility: visible !important; backface-visibility: visible !important; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes pulse {
	from { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }

	50% { -webkit-transform: scale3d(1.05, 1.05, 1.05); transform: scale3d(1.05, 1.05, 1.05); }

	to { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

@keyframes pulse {
	from { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }

	50% { -webkit-transform: scale3d(1.05, 1.05, 1.05); transform: scale3d(1.05, 1.05, 1.05); }

	to { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

.pulse { -webkit-animation-name: pulse; animation-name: pulse; }

@keyframes slide {
	0% { transform: translate3d(0, 0, 0); }

	100% { transform: translate3d(-1692px, 0, 0); }
}


@keyframes fadein {
		0% { opacity:0; }
		66% { opacity:0; }
		100% { opacity:1; }
}

@-webkit-keyframes fadein {
		0% { opacity:0; }
		66% { opacity:0; }
		100% { opacity:1; }
}

@-webkit-keyframes flash {
	from,
	50%,
	to {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}

@keyframes flash {
	from,
	50%,
	to {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}

.flash {
	@include animate-elm(); -webkit-animation-name: flash; animation-name: flash;
}

@mixin animate-flash() {
	@include animate-elm(); -webkit-animation-name: flash; animation-name: flash;
}





@-webkit-keyframes slide-in-down  {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slide-in-down  {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@mixin slide-in-down() {
	-webkit-animation-name: slide-in-down ;
	animation-name: slide-in-down ;
}




@-webkit-keyframes slide-in-up {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slide-in-up {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@mixin slide-in-up() {
	-webkit-animation-name: slideInUp;
	animation-name: slideInUp;
}
