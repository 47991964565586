.wrapper-footer {
	position: relative;
}

.phone,
.email {
	@include txt-font-16($white);
	font-weight: 800;
	letter-spacing: -0.44px;
	line-height: 20px;
}

.copyright {
	border-top: 1px solid $links;
	padding-top: 0.5rem;

	p {
		@include txt-font-12($white, 800);
		font-weight: 500;
		letter-spacing: -0.33px;
		line-height: 14px;
		margin: 0;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding: 0.5rem;
			text-align: center;
		}
	}
}

.footer-logo {
	display: none;
}

.footer-logo-link {
	height: 6rem;
	width: 14rem;
	margin: 4rem auto;
}

.footer-sm {
	.ico {
		text-align: center;
		color: $white;
		margin: 0 1rem 2rem;
		height: 0;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&::before {
		padding: 10px;
		font-size: 1.5rem;
	}

	&:hover {
		color: $brand-accent;
	}
}
.social-icons {
	text-align: center;
}

footer {
	.p-social {
		&:hover {
			color: $black;
		}
	}
	.footer-sm:hover {
		.p-social {
			color: $black;
		}
	}
}

.footer {
	position: relative;
	

	.row {
		justify-content: space-around;
		align-items: center;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			align-items: center;
			flex-direction: column; 
			justify-content: center;
		}
	}

	.footer-sm {
		text-align: center;
		width: 100%;
	}
	.container-fluid{
		max-width: 100%;
	}
}

.contact-data{
	text-align: left;
	margin: auto;
	max-width: 1100px !important;
	.subtitle-line{
		color: $brand-primary;
		font-weight: 700;
		margin-bottom: 4rem;
		padding-bottom: 2rem;
		border-bottom: 3px solid $brand-secondary;
		width: 100%;
	}
	.contact-mail{
		color: $brand-secondary;
		font-weight: 400;
		font-size: 64px;
		width: 100%;
	}
	.d-flex{
		margin-bottom: 1rem;
		padding-bottom: 2rem;
		border-bottom: 3px solid $brand-secondary;
	}
	p{
		color: $brand-secondary;
		font-size: 24px;
		font-family: 'Roboto Slab', serif;
	}
}

.client-slogan{
	color: $brand-secondary;
		font-size: 24px;
		font-family: 'Roboto Slab', serif;
		margin-bottom: 6rem;
}

.contact-f {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		flex-direction: column;
	}

	a:hover {
		color: $black-generic;
	}

	p {
		color: $black-generic;
		text-align: left;
	}
}
.footer-alt{
	background: $black-light;
	padding-top: 2rem;	
	display: flex;
	align-items: flex-end;
	p{
		color: $white;
		font-size: 18px;
		font-weight: 600;
	}
}
.last-foot{
	border-top: 9px solid $brand-primary;
	background: $black;
	padding: 0 2rem;
	.d-flex{
		max-width: 1100px !important;
		margin-left: auto;
		margin-right: auto;
	}
}

.footer-menu{
	text-align: center;
	margin: 2rem auto;
	color: $white;
	.foot-title{
		font-size: 28px;
		margin-bottom: 2rem;
		font-weight: 600;
		font-family: 'Roboto Slab', serif;
	}
	a{
		color: $white;
	}
}

.footer-sm-fb {
	&.ico {
		color: #4967a2;
	}
}

.footer-sm-tw {
	&.ico {
		color: #00a2f2;
	}
}

.footer-sm-in {
	&.ico {
		color: #0086bd;
	}
}

.footer-sm-yt {
	&.ico {
		color: #ed1c24;
	}
}

.link-privacidad {
	display: block;
	@include txt-font-20($black-generic, 100);
	line-height: 40px;
	letter-spacing: -0.33px;
	font-weight: 600;
	color: $white;

	@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
		margin-left: auto;
		margin: 0 auto 1rem;
		display: block;
	}

	&:hover {
		@include txt-font-20($black-generic, 100);
		line-height: 40px;
	}
	&:last-of-type {
		margin-right: 0;
	}
}
