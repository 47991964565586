/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */




// vars
$_header-type              : absolute !default;
$_header-mobile-breakpoint : 'md' !default;

$_header-xs-height         : 6.4rem !default;
$_header-xl-height         : 6.4rem !default;

$_header-offset-after      : 0 !default;
$_header-content-offset-xs : $_header-xs-height + $_header-offset-after !default;
$_header-content-offset-xl : $_header-xl-height + $_header-offset-after !default;

$_header-logo-image        : false !default;
$_header-logo-xs-w         : 6.4rem !default;
$_header-logo-xs-h         : 6.4rem !default;
$_header-logo-xl-w         : $_header-logo-xs-w !default;
$_header-logo-xl-h         : $_header-logo-xs-h !default;

$_header-z-index           : 10 !default;

$_header-flex              : true !default;
$_header-flex-align        : center !default;
$_header-flex-justify      : space-between !default;

$_enable_sticky_header     : false !default;

$_header_width             : 100% / 6 !default;
$_header_position          : left !default;




// Mixin
// **************************************************

@mixin menu-header { height: $_header-xs-height; width: 100%; }

@mixin menu-header-type($_header-type: '') {

	@if $_header-type == absolute {

		.header  {
			@include menu-header; height: $_header-xs-height; left: 0; position: absolute; top: 0; z-index: $_header-z-index;
			@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { height: $_header-xl-height; }
		}

		.content {
			margin-top: $_header-content-offset-xs;
			@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { margin-top: $_header-content-offset-xl; }
		}
	}

	@elseif $_header-type == fixed {

		.header  {
			@include menu-header; left: 0; position: fixed; top: 0; z-index: $_header-z-index;
			@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { height: $_header-xl-height; }
		}

		.content {
			margin-top: $_header-content-offset-xs;
			@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { margin-top: $_header-content-offset-xl; }
		}
	}

	@else {
		.header  {
			@include menu-header; position: relative;
			@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { height: $_header-xl-height; }
		}
	}
}




// Common
// **************************************************

@if $_header-type == vertical {

	@media all and (max-width: map-get($grid-breakpoints, $_header-mobile-breakpoint) - 1)
	{
		@include menu-header-type(fixed);
	}

	@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint))
	{

		html,
		body { height: 100%; min-height: 100vh; }

		.header  {
			height: 100vh; position: fixed; top: 0; width: $_header_width; z-index: $_header-z-index;

			@if $_header_position == right {
				right: 0;
			}

			@else {
				left: 0;
			}

		}
		.content,
		.footer { margin-left: $_header_width; }
	}
}

@else
{
	@include menu-header-type($_header-type);
}




// Apply flex to header elements
// **************************************************
@if $_header-flex
{
	.header .container-fluid {
		align-items: $_header-flex-align; display: flex; justify-content: $_header-flex-justify;

		@if $_header-type == vertical {
			@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { flex-direction: column; }
		}
	}
}




// Logo
// **************************************************

@if $_header-logo-image
{
	.site-logo-link {
		@include hide-text; background: url(#{$_image-path}/#{$_header-logo-image}) center no-repeat; background-size: contain; display: block; height: $_header-logo-xs-h; width: $_header-logo-xs-w;

		@if ($_header-logo-xs-h != $_header-logo-xl-h) or ($_header-logo-xs-w != $_header-logo-xl-w) {
			@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { height: $_header-logo-xl-h; width: $_header-logo-xl-w; }
		}
	}
}




// Sticky header class
@if $_enable_sticky_header
{
	// implementar sticky!!
}
