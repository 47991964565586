/*
 * LAYOUT: MENU
 * by SalsaLab
 */




// vars
$_menu-mobile-type       : dropdown !default;
$_menu-mobile-breakpoint : 'md' !default;
$_menu-mobile-offset     : 4.8rem !default;
$_menu-mobile-width      : 100vw !default;

$_menu-direction         : horizontal !default;
$_menu-justify-node      : flex-start !default;
$_menu-node-width        : false !default;

$_menu-has-children      : false !default;
$_menu-children-bg       : $body-bg !default;
$_menu-children-shadow   : 0 0.2rem 0.4rem transparentize($black, 0.8), 0 0.1rem 1rem transparentize($black, 0.9) !default;




// Common
// **************************************************

.menu {
	//
	a { cursor: pointer; display: block; text-decoration: none; }
}

.menu-mobile { cursor: pointer; }




// MOBILE: Dropdown Menu
// **************************************************


@if $_menu-mobile-type == dropdown {

	@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1px) {

		.menu {
			position: relative;

			&.active {
				//
				.menu-content { opacity: 1; pointer-events: all; }
			}

			a { display: block; width: 100%; }
		}

		.menu-content {
			left: 0; opacity: 0; pointer-events: none; position: absolute; top: $_menu-mobile-offset; transition: $transition-fade; width: $_menu-mobile-width;
			&.active { display: block; opacity: 1; }
		}

		.menu-mobile {}
		.mnu {}
	}
}




// DESKTOP: Horizontal Menu
// **************************************************

@if $_menu-direction == horizontal {

	@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint)) {

		.menu {}

		.mnu {

			@if $_menu-node-width {
				//
			}
		}

		.menu-mobile { display: none; }
		.menu-content { display: flex; justify-content: $_menu-justify-node; }


		// children
		@if $_menu-has-children
		{
			.mnu { position: relative; }

			.children {
				background: $_menu-children-bg; box-shadow: $_menu-children-shadow; display: block; pointer-events: none; opacity: 0; position: absolute; transition: $transition-fade;

				.mnu {}
				a {}
			}

			.mnu:hover .children,
			.children:hover { opacity: 1; pointer-events: all; }
		}
	}
}
