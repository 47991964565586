/**
 * LIGHTBOX basic styles
 * by SalsaLab
 */


$_lightbox-breakpoint      : 'md' !default;

$_lightbox-overlay-color   : $black !default;
$_lightbox-overlay-opacity : 0.05 !default;

$_lightbox-z-index         : 100 !default;
$_lightbox-body-overflow   : true !default;
$_lightbox-content-padding : 0 !default;




%lightbox-overlay { left: 0; height: 100vh; position: fixed; top: 0; width: 100vw; }

.slb-lightbox-container {
	@extend %lightbox-overlay; background: transparentize($_lightbox-overlay-color, $_lightbox-overlay-opacity); opacity: 0; pointer-events: none; transition: $transition-base; z-index: $_lightbox-z-index + 1;
	&.active { opacity: 1; pointer-events: all; }
}


.slb-lightbox-nav {
	height: 0;
	a { cursor: pointer; position: absolute; z-index: $_lightbox-z-index + 5; }
}

.slb-lightbox-prev { left: 0; top: 50%; transform: translateY(-50%); }
.slb-lightbox-next { right: 0; top: 50%; transform: translateY(-50%); }
.slb-lightbox-close { right: 0; top: 0; }

.slb-lightbox-media {
	@extend %lightbox-overlay; z-index: $_lightbox-z-index + 2;
	@media all and (min-width: map-get($grid-breakpoints, $_lightbox-breakpoint)) { padding: $_lightbox-content-padding; }

	figure,
	div { height: 100%; margin: 0; position: relative; width: 100%; }

	figure {
		//

		&.loading:before { animation: loading 1s ease-in-out infinite alternate; background: $white; content: ''; border-radius: 2rem; display: block; height: 2rem; left: 50%; margin: -1rem 0 0 -1rem; position: absolute; top: 50%; width: 2rem; z-index: $_lightbox-z-index + 3; }
	}

	img,
	video,
	iframe {
		animation: fadein 0.25s; height: auto; left: 50%; max-height: 100%; max-width: 100%; position: absolute; top: 50%; transform: translate(-50%, -50%); transition: $transition-fade; z-index: $_lightbox-z-index + 2;

		&.remove-queued { opacity: 0; }
	}

	video { background: $black; }
	iframe { background: $black; border: 0; display: block; height: 100%; width: 100%; }

	figcaption {
		//

		&:empty { display: none; }
	}
}

@keyframes fadein
{
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes loading
{
	from { transform: scale(1); }
	to { transform: scale(3); }
}




// hide body scrollbar

@if $_lightbox-body-overflow
{
	.slb-lightbox-active { overflow: hidden; }
}
